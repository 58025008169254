import React, { useState, useEffect } from 'react';
import { db, collection, getDocs, Timestamp, query, where } from '../../../../firebaseConfig'; // Add query and where
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faAngleUp, faAngleDown, faEquals } from '@fortawesome/free-solid-svg-icons';
import './WaitlistSubscriptions.css'; // Create the CSS for styling

const WaitlistSubscription = ({ dateOption, preciseDate, startDate, endDate }) => {
    const [waitlistUsers, setWaitlistUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalWaitlist, setTotalWaitlist] = useState(0);
    const [waitlistInRange, setWaitlistInRange] = useState(0);
    const [growthPercentage, setGrowthPercentage] = useState(0);

    useEffect(() => {
        fetchWaitlistUsers();
    }, [dateOption, preciseDate, startDate, endDate]);

    const fetchWaitlistUsers = async () => {
        try {
            const waitlistCollectionRef = collection(db, 'waitlist');
            let waitlistQuery = waitlistCollectionRef;
    
            if (startDate && endDate) {
                // Use Firestore "where" queries to filter users by timestamp within the start and end date
                waitlistQuery = query(
                    waitlistCollectionRef,
                    where('timestamp', '>=', startDate),
                    where('timestamp', '<=', endDate)
                );
            }
    
            const querySnapshot = await getDocs(waitlistQuery);
            const waitlistData = [];
            querySnapshot.forEach((doc) => {
                waitlistData.push({ id: doc.id, ...doc.data() });
            });
    
            const totalWaitlistCount = waitlistData.length;
            const waitlistInRangeCount = calculateWaitlistInRange(waitlistData);
    
            setWaitlistUsers(waitlistData);
            setTotalWaitlist(totalWaitlistCount);
            setWaitlistInRange(waitlistInRangeCount);
            setGrowthPercentage(calculateGrowthPercentage(totalWaitlistCount, waitlistInRangeCount));
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching waitlist data:", error);
            setIsLoading(false);
        }
    };
    

    const calculateWaitlistInRange = (waitlistData) => {
        if (dateOption === 'all') {
            return waitlistData.length;
        }

        if (dateOption === 'precise') {
            return waitlistData.filter(user => {
                const userDate = new Date(user.timestamp.seconds * 1000); // convert Firestore timestamp to Date
                return userDate.toDateString() === preciseDate.toDateString();
            }).length;
        } else {
            return waitlistData.filter(user => {
                const userDate = new Date(user.timestamp.seconds * 1000); // convert Firestore timestamp to Date
                return userDate >= startDate && userDate <= endDate;
            }).length;
        }
    };

    const calculateGrowthPercentage = (totalWaitlist, waitlistInRange) => {
        if (totalWaitlist === 0) return 0;
        return ((waitlistInRange / totalWaitlist) * 100).toFixed(2);
    };

    const getGrowthClass = (growthPercentage) => {
        if (growthPercentage > 0) {
            return 'waitlist-growth-positive';
        } else if (growthPercentage < 0) {
            return 'waitlist-growth-negative';
        } else {
            return 'waitlist-growth-null';
        }
    };

    const getGrowthIcon = (growthPercentage) => {
        if (growthPercentage > 0) {
            return faAngleUp;
        } else if (growthPercentage < 0) {
            return faAngleDown;
        } else {
            return faEquals;
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="waitlist-container">
            <div className="waitlist-box">
                <div className="waitlist-summary">
                    <p className='waitlist-summary-title'><FontAwesomeIcon icon={faPlusCircle} /> Waitlist sign ups</p>
                    <div className='waitlist-summary-count'>
                        <p className='waitlist-count'>{waitlistInRange}</p>
                        <p className={`waitlist-growth ${getGrowthClass(growthPercentage)}`}>
                            {growthPercentage}% <FontAwesomeIcon icon={getGrowthIcon(growthPercentage)} />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WaitlistSubscription;
