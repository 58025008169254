import { useState } from "react";
import { useNavigate } from 'react-router-dom'; // For navigation if using react-router
import { db, addDoc, collection } from '../../firebaseConfig';  // Ensure Firebase config is imported correctly
import './Waitlist.css';

const WaitlistInput = () => {
  const [waitlistButton, setwaitlistButton] = useState("Join Waitlist");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();  // useNavigate for programmatic navigation
  
  const handleSubmit = async () => {
    if (!email) {
      setError("Email is required");
      return;
    }
  
    try {
      setwaitlistButton("Submitting...");
  
      // Send a request to Firebase Function to send the email
      const response = await fetch('https://us-central1-third--space.cloudfunctions.net/addToWaitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        setSuccessMessage("You’re now on the waitlist. We’ll notify you when early access is available.");
        setwaitlistButton(<>You're In! <i className="bi bi-check"></i></>);
        setEmail("");  // Clear input
        setError("");  // Clear any previous errors
      } else {
        setError(result.message || "Something went wrong. Please try again.");
        setwaitlistButton("Join Waitlist"); // Reset button
      }
    } catch (err) {
      setError("There was an error submitting your request.");
      setwaitlistButton("Oops...");
      console.log(err);
    }
  };

  // Function to handle redirection
  const redirectToTerms = () => {
    navigate('/terms'); // Adjust URL based on your routing
  };

  const redirectToPrivacyPolicies = () => {
    navigate('/privacy-policy'); // Adjust URL based on your routing
  };
  
  return (
    <section className="waitlist-input">
      <div className="waitlist-subscription">
        <input
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={handleSubmit}>{waitlistButton}</button>
      </div>
      {error && <p className="waitlist-error">{error}</p>}
      {successMessage && <p className="waitlist-success"> {successMessage}</p>}
      <p>
        By clicking "Join Waitlist", you're agreeing to the 
        <span onClick={redirectToTerms}>Terms</span> 
        and 
        <span onClick={redirectToPrivacyPolicies}>Privacy Policies.</span>
      </p>
    </section>
  );
};

export default WaitlistInput;
