import React from "react";
import "./FeaturesDashboard.css";

const FeaturesDashboard = () => {
  return (
    <div className="features-dashboard">
      <h1>Features</h1>
    </div>
  );
};

export default FeaturesDashboard;
