import React from "react";
import "./HelpCenter.css";

const HelpCenter = () => {
  return (
    <div className="help-center-dashboard">
      <h1>Help Center</h1>
    </div>
  );
};

export default HelpCenter;
